<template>
  <div class="py-5 px-2">
    <div
      class="d-flex align-items-center mt-3 pb-2"
      style="border-bottom: 2px solid #dadada"
    >
      <profile-image
        :user="user"
        v-if="user"
        class="profile-img img-thumbnail rounded-circle"
      ></profile-image>
      <!-- <img
        v-if="user"
        :src="user.avatar ? user.avatar : 'http://www.gravatar.com/avatar'"
        class="profile-img d-block"
        alt="poster-image"
      /> -->
      <div class="user-details ms-3" v-if="user">
        <h1 class="user-name text-center">
          {{ user.first_name }} {{ user.last_name }}
          <i
            class="bi bi-patch-check-fill text-success ms-1"
            v-if="user.email_verified_at != null"
          ></i>
        </h1>
        <router-link :to="`/profile/${user.id}`" class="view-profile-text">
          View Profile
        </router-link>
      </div>
    </div>
    <div class="mt-2 d-flex justify-content-between flex-column">
      <div class="">
        <div
          class="d-flex align-items-center justify-content-between py-1 px-2"
        >
          <div class="d-flex align-items-center">
            <i class="bi bi-chat-fill fs-5"></i>
            <span class="chats-text ms-3">Chats</span>
          </div>
          <span class="badge bg-danger rounded-pill p-2">0</span>
        </div>
        <div
          class="d-flex align-items-center justify-content-between py-1 px-2"
        >
          <div class="d-flex align-items-center">
            <i class="bi bi-question-circle-fill fs-5"></i>
            <span class="chats-text ms-3">Help & Support</span>
          </div>
          <i class="bi bi-chevron-right"></i>
        </div>
        <div
          class="d-flex align-items-center justify-content-between py-1 px-2"
        >
          <div class="d-flex align-items-center">
            <i class="bi bi-gear fs-5"></i>
            <span class="chats-text ms-3">Settings</span>
          </div>
          <i class="bi bi-chevron-right"></i>
        </div>
      </div>
      <div
        class="d-flex align-items-center justify-content-between py-1 px-2 logout"
      >
        <div class="d-flex align-items-center" @click="logout">
          <i class="bi bi-gear fs-5"></i>
          <span class="chats-text ms-3">Log Out</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import ProfileImage from "@/components/profile/ProfileImage.vue"
export default {
  name: "Settings",
  components: {
    ProfileImage
  },
  computed: {
    ...mapState("user", ["user"])
  },
  methods: {
    ...mapActions("auth", ["logout"])
  }
}
</script>

<style scoped>
.profile-img {
  aspect-ratio: 1/1;
  width: 56px;
  height: 56px;
  object-fit: cover;

  border-radius: 100px;
}

/* user-details */
.user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.view-profile-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;

  /* identical to box height */
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #6d6d6d;
  margin-top: -0.5rem;
}
.chats-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;

  /* identical to box height */
  display: flex;
  align-items: center;

  color: #000000;
}
.logout {
  margin-top: 4rem;
}
</style>
